import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { MainLayout } from '@/components';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { getPageInfo } from '@/config/pages';
import { NextPage } from '@/types/nextjs';

const Page: NextPage = () => {
  return (
    <Box
      bgImage={'/images/background.jpg'}
    >
      <Box
        display={{ base: 'block', lg: 'flex' }}
        justifyContent={{ lg: 'center' }}
        mx={10}
        py={{ base: 10, lg: 20 }}
      >
        <Box
          w={{ base: '60%', lg: '18%' }}
          mx={{ base: 'auto', lg: 0 }}
          as={'h1'}
        >
          <Image
            src={'/images/error_page/404.png'}
            alt={'404 not found'}
            width={896}
            height={1188}
          />
        </Box>
        <Box
          w={{ base: '100%', lg: '57%' }}
          pl={{ base: 0, lg: 10 }}
        >
          <Box
            textAlign={'justify'}
            as={'h2'}
            textStyle={'h1'}
            textColor={'#45612C'}
            fontWeight={'bold'}
            letterSpacing={{ base: 'none', lg: 4 }}
            pt={{ base: 4, lg: 8 }}
            pb={{ base: 3, lg: 6 }}
          >
            ご指定のページが見つかりません。
          </Box>
          <Box
            pb={6}
            textAlign={'justify'}
            textStyle={{ base: 'h6', lg: 'h5' }}
            lineHeight={{ base: 1.5, lg: 8 }}
          >
            <Text>申し訳ありません。</Text>
            <Text>お探しのページは一時的にアクセスが出来ない状況にあるか、もしくは移動、削除され見つけることができません。</Text>
            <Text>お手数ですが、上部メニューバーから目的のページをお探し下さい。</Text>
          </Box>
          <LinkButton
            display={'block'}
            width={{ base: '100%', lg: '42%' }}
            text={'トップページへ戻る'}
            linkUrl={getPageInfo('top', {})}
            my={{ base: 2, lg: 6 }}
            mx={{ base: 'auto', lg: 0 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

/**
 * canonical、ogは不要
 * @param page
 */
Page.getLayout = (page) => {
  return (
    <MainLayout
      metaTagInfo={{
        title: 'ページが見つかりませんでした',
      }}
      isShowBreadcrumb={false}
    >
      {page}
    </MainLayout>
  );
};

export default Page;
